<template>
    <div class="pa-4">
        <v-row
            justify="space-between"
            align-content="center"
        >
            <v-col cols="12" md="6">
                <h1 class="text--xl font-weight-medium">{{ $t(this.$route.params.id ? 'navigation.editTrack' : 'navigation.addTrack') }}</h1>
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.close') }}
                </v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="saveTrack">{{ $t('buttons.save') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" :class="{ 'v-skeleton-loader': isLoadingActivePoints, 'theme--light': isLoadingActivePoints }">
                <div class="elevation-1 rounded" :class="{ 'isLoading': isLoadingActivePoints, 'v-skeleton-loader__bone': isLoadingActivePoints }">
                    <v-tabs :dark="false"
                            :icons-and-text="false"
                            :grow="false">
                        <v-tab v-for="(tab, index) in tabs" :key="index">
                            <v-icon top class="mr-2">
                                {{ tab.icon }}
                            </v-icon>
                            {{ tab.title }}
                        </v-tab>
                        <v-tab-item eager>
                            <fieldset>
                                <div style="overflow-y: auto; max-height: 60vh">
                                    <PointsTree
                                        :customers="customersList"
                                        :loadedAllCustomers="loadedAllCustomers"
                                        :ref="treesRefs.point">
                                    </PointsTree>
                                </div>
                            </fieldset>
                        </v-tab-item>
                        <v-tab-item eager>
                            <fieldset>
                                <div style="overflow-y: auto; max-height: 60vh">
                                    <ElementsTree
                                        :elements="getFilteredElements(allElementsList, elementsList)"
                                        :ref="treesRefs.element"
                                        :initiallySelected="activeElements"
                                        @get-elements="getElements($event, false)">
                                    </ElementsTree>
                                </div>
                            </fieldset>
                        </v-tab-item>
                        <v-tab-item eager>
                            <fieldset>
                                <div style="overflow-y: auto; max-height: 60vh">
                                    <EmployeesTree
                                        :employeesTree="getFilteredElements(allEmployeesList, employeesList)"
                                        :ref="treesRefs.employee"
                                        :initiallySelected="activeEmployees"
                                        @get-employees="getEmployees($event, false)">
                                    </EmployeesTree>
                                </div>
                            </fieldset>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="full-width">
                    <ValidationObserver ref="trackBasicConfig">
                        <v-row>
                            <v-col>
                                <TextField
                                    :label="$t('tracks.track_name')"
                                    v-model="name"
                                    rules="required">
                                </TextField>
                            </v-col>
                            <v-col>
                                <Autocomplete
                                    :label="$t('labels.location')"
                                    rules="required"
                                    :items="locationItems"
                                    name="location"
                                    :loading="isLocationsLoading"
                                    :search-input.sync="searchLocations"
                                    @focus="locations.length === 0 && getLocations(null)"
                                    @load-more-items="getLocationsLazyLoading"
                                    v-model="point.id"
                                    @change="updateAutocomplete($event, 'point', locationItems)"
                                ></Autocomplete>
                            </v-col>
                            <v-col>
                                <Autocomplete :label="$t('labels.group')"
                                    :items="filterableEmployeesGroups"
                                    name="employeesGroups"
                                    ref="employeesGroups"
                                    clearable
                                    :loading="isEmployeesGroupsLoading"
                                    :search-input.sync="searchEmployeesGroups"
                                    @load-more-items="getEmployeesGroupsLazyLoading"
                                    v-model="employeeGroup"
                                ></Autocomplete>
                                <Autocomplete
                                    :label="$t('navigation.employees')"
                                    :items="assignedEmployeeItems"
                                    :loading="isEmployeesForSelectLoading"
                                    :search-input.sync="searchEmployeesForSelect"
                                    name="employeesIds"
                                    clearable
                                    @focus="employeesForSelect.length === 0 && getEmployeesForSelect({ 'filter[is_item]': false, 'filter[employee_group_id]': employeeGroup })"
                                    @load-more-items="getEmployeesLazyLoadingForSelect({ 'filter[is_item]': false, 'filter[employee_group_id]': employeeGroup })"
                                    rules="required"
                                    v-model="assignedEmployee.id"
                                    @change="updateAutocomplete($event, 'assignedEmployee', assignedEmployeeItems)"
                                ></Autocomplete>
                            </v-col>
                        </v-row>
                        <h3> {{ $t('tracks.track_type') }} </h3>
                        <v-row>
                            <v-col cols="12" md="8">
                                <SelectField
                                    :label="$t('cyclicRevisions.trackParams.track')"
                                    v-model="type"
                                    rules="required"
                                    name="type"
                                    alternative-validation-name="type"
                                    :items="sequenceTypes"></SelectField>
                            </v-col>
                            <v-col cols="12" md="4">
                                <DurationPicker
                                    v-model="durationTime"
                                    :rules="type !== TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME ? 'requiredDuration' : ''"
                                    :reset-item="type"
                                    :reset-values="[TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME]"
                                    :disabled="type === null"
                                    :hidden="type === TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME"
                                ></DurationPicker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="4">
                                <Autocomplete
                                    name="incidentTypeId"
                                    rules="required"
                                    :label="$t('labels.incident_type')"
                                    :items="incidentTypeItems"
                                    :loading="isTypesLoading"
                                    :search-input.sync="searchTypes"
                                    @focus="filterableTypesList.length === 0 && getIncidentsTypes()"
                                    @load-more-items="getIncidentsTypesLazyLoading"
                                    v-model="incidentType.id"
                                    @change="updateAutocomplete($event, 'incidentType', incidentTypeItems)"
                                ></Autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="4">
                                <Autocomplete name="inspectionTemplateId"
                                    :label="$t('labels.inspection_template')"
                                    :items="inspectionTemplateItems"
                                    :loading="isTypesLoading"
                                    :disabled="!point.id"
                                    @focus="inspectionTemplates.length === 0 && getInspectionTemplates()"
                                    v-model="inspectionTemplate.id"
                                    @change="updateAutocomplete($event, 'inspectionTemplate', inspectionTemplateItems)"
                                ></Autocomplete>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </div>
                <TrackConfigurationTable
                    ref="trackConfigTable"
                    :headers="headers"
                    :items="steps"
                    @copy="handleCopy"
                    @delete="handleDelete"
                    @drag-update="updateTasksSequence($event)"
                    :isLoading="isLoadingActivePoints"
                ></TrackConfigurationTable>
            </v-col>
        </v-row>
        <TrackForm ref="trackForm">
        </TrackForm>
    </div>
</template>

<script>
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import DurationPicker from '@/components/widgets/forms/DurationPicker';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';
import EmployeesTree from '@/components/cyclicRevisions/manageTrack/EmployeesTree.vue';
import ElementsTree from '@/components/cyclicRevisions/manageTrack/ElementsTree.vue';
import PointsTree from '@/components/cyclicRevisions/manageTrack/PointsTree.vue';
import TextField from '@/components/widgets/forms/TextField';
import TrackConfigurationTable from '@/components/cyclicRevisions/manageTrack/TrackConfigurationTable.vue';
import SelectField from '@/components/widgets/forms/SelectField';
import TrackForm from '@/components/cyclicRevisions/manageTrack/TrackForm.vue';
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapState } from 'vuex';
import { STEP_ELEMENT_TYPE_ENUM, TRACK_TYPE_ENUM, TRACK_TYPE_TRANSLATE_KEYS } from '@/constants/cyclicRevision';
import { ValidationObserver } from 'vee-validate';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import { ICONS } from '@/constants/icons';
import debouncedEmployeesForSelect from '@/mixins/debounced/debouncedEmployeesForSelect';

export default {
    name: 'TrackManagement',
    components: {
        Autocomplete,
        DurationPicker,
        PointsTree,
        ElementsTree,
        EmployeesTree,
        TextField,
        SelectField,
        TrackForm,
        TrackConfigurationTable,
        ValidationObserver
    },
    mixins: [
        debouncedLocations,
        debouncedEmployees,
        debouncedEmployeesForSelect,
        debouncedEmployeesGroups,
        debouncedIncidentTypes
    ],
    data: () => ({
        customersList: [],
        elementsList: [],
        allElementsList: [],
        employeesList: [],
        allEmployeesList: [],
        employeesForSelectList: [],
        localSelectedTrackComponents: [],
        loader: false,
        treesRefs: {
            point: 'points-tree',
            element: 'elements-tree',
            employee: 'employees-tree'
        },
        TRACK_TYPE_ENUM,
        employeeGroup: null,
        inspectionTemplates: [],
        loadedAllCustomers: false,
        activeElements: [],
        activeEmployees: []
    }),
    computed: {
        ...mapFields('manageTrack', [
            'trackData.id',
            'trackData.name',
            'trackData.sequence.type',
            'trackData.sequence.steps',
            'trackData.sequence.activeSteps',
            'trackData.sequence.durationTime',
            'trackData.point',
            'trackData.incidentType',
            'trackData.inspectionTemplate',
            'trackData.assignedEmployee'
        ]),
        ...mapState('manageTrack', [
            'inspectionsTemplates'
        ]),
        ...mapGetters('manageTrack', [
            'activeTrack'
        ]),
        ...mapState('cyclicRevisions', [
            'selectedTrackElements',
            'selectedTrackPoints',
            'selectedTrackEmployees',
            'allActivePointsSelected'
        ]),
        ...mapGetters('employees', [
            'employees',
            'employeesForSelect'
        ]),
        ...mapGetters('employeesGroups', [
            'filterableEmployeesGroups'
        ]),
        ...mapGetters('locations', [
            'locations'
        ]),
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapState('customers', [
            'sitesPerCustomers'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ]),
        tabs () {
            return [
                {
                    icon: ICONS.POINT,
                    title: this.$t('track.trees.locations')
                },
                {
                    icon: ICONS.ELEMENT,
                    title: this.$t('track.trees.elements')
                },
                {
                    icon: ICONS.ACCOUNT,
                    title: this.$t('track.trees.employees')
                }
            ];
        },
        headers () {
            let headerList = [
                {
                    text: this.$t('tracks.table.lp'),
                    value: 'lp',
                    sortable: false
                },
                {
                    text: this.$t('tracks.table.title'),
                    value: 'title',
                    sortable: false
                },
                {
                    text: this.$t('tracks.table.id_type'),
                    value: 'id_type',
                    sortable: false
                }
            ];

            if (this.type === TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME) {
                headerList = headerList.concat([
                    {
                        text: this.$t('tracks.table.time'),
                        value: 'time',
                        sortable: false
                    },
                    {
                        text: this.$t('tracks.table.tolerance'),
                        value: 'tolerance'
                    }
                ]);
            }

            headerList = headerList.concat([
                {
                    text: this.$t('tracks.table.actions'),
                    value: 'actions'
                }
            ]);

            return headerList;
        },
        sequenceTypes () {
            return [
                {
                    value: TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME,
                    text: this.$t(TRACK_TYPE_TRANSLATE_KEYS[TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME])
                },
                {
                    value: TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM,
                    text: this.$t(TRACK_TYPE_TRANSLATE_KEYS[TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM])
                },
                {
                    value: TRACK_TYPE_ENUM.RAND_IN_TIME,
                    text: this.$t(TRACK_TYPE_TRANSLATE_KEYS[TRACK_TYPE_ENUM.RAND_IN_TIME])
                }
            ];
        },
        locationItems () {
            const current = this.point.id ? [{ value: this.point.id, text: this.point.name }, { divider: true }] : [];
            return [...current, ...this.getSelectItems(this.locations, this.point)];
        },
        assignedEmployeeItems () {
            const current = this.assignedEmployee.id ? [{ value: this.assignedEmployee.id, text: this.assignedEmployee.name }, { divider: true }] : [];
            return [...current, ...this.getSelectItems(this.employeesForSelect, this.assignedEmployee)];
        },
        incidentTypeItems () {
            const current = this.incidentType.id ? [{ value: this.incidentType.id, text: this.incidentType.name }, { divider: true }] : [];
            return [...current, ...this.getSelectItems(this.filterableTypesList, this.incidentType)];
        },
        inspectionTemplateItems () {
            const current = this.inspectionTemplate.id ? [{ value: this.inspectionTemplate.id, text: this.inspectionTemplate.name }, { divider: true }] : [];
            return [...current, ...this.getSelectItems(this.inspectionTemplates, this.inspectionTemplate)];
        },
        allSteps () {
            return this.activeSteps?.map(activeStep => {
                const step = this.steps.find(step => step.id === activeStep.point.id) || this.steps.find(step => step.id === activeStep.employee.id);
                return {
                    ...step,
                    name: activeStep.point.name || activeStep.employee.name,
                    parent_id: activeStep.order === 0 ? null : activeStep.order,
                    template: {
                        id: activeStep.template.id,
                        value: activeStep.value
                    },
                    value: activeStep.value,
                    verificationWay: activeStep.verificationWay,
                    timeSinceSequenceStart: activeStep.timeSinceSequenceStart,
                    tolerance: activeStep.tolerance
                };
            }) || [];
        },
        isLoadingActivePoints () {
            return this.activeSteps && this.activeSteps.length > 0;
        }
    },
    watch: {
        selectedTrackElements: {
            handler () {
                this.handleTrackElementChange(this.selectedTrackElements, STEP_ELEMENT_TYPE_ENUM.ELEMENT);
                this.recalculateElementsArray(this.localSelectedTrackComponents);
            },
            deep: true
        },
        selectedTrackPoints: {
            handler () {
                this.handleTrackElementChange(this.selectedTrackPoints, STEP_ELEMENT_TYPE_ENUM.POINT);
                this.recalculateElementsArray(this.localSelectedTrackComponents);
            },
            deep: true
        },
        selectedTrackEmployees: {
            handler () {
                this.handleTrackElementChange(this.selectedTrackEmployees, STEP_ELEMENT_TYPE_ENUM.EMPLOYEE);
                this.recalculateElementsArray(this.localSelectedTrackComponents);
            },
            deep: true
        },
        durationTime: {
            handler (val) {
                if (val && this.type !== TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME) {
                    this.$refs.trackConfigTable.recalculateDurationTime();
                }
            }
        },
        steps: {
            handler (val) {
                if (!this.activeSteps || val.length === 0) return;

                const uniqueSteps = new Set(this.activeSteps.map(step => `${step.point.id}-${step.employee.id}`));

                if (this.allActivePointsSelected && val.length === uniqueSteps.size) {
                    this.localSelectedTrackComponents = this.allSteps;
                    this.recalculateElementsArray(this.allSteps);
                    this.activeSteps = [];
                }
            }
        },
        employeeGroup: {
            handler () {
                this.$store.commit('employees/SET_RAW_EMPLOYEES_FOR_SELECT', []);
            }
        }
    },
    methods: {
        updateTasksSequence (event) {
            this.localSelectedTrackComponents = event;
            this.recalculateElementsArray(this.localSelectedTrackComponents);
        },
        async getTreesElements () {
            await this.getCustomers();
            await this.getElements();
            await this.getEmployees();
        },
        getSelectItems (getter, { id, name }) {
            return getter.length
                ? getter : id
                    ? [{ value: id, text: name }] : [];
        },
        getActiveTrack (id) {
            if (!id) return;
            this.$store.dispatch('manageTrack/getActiveTrack', id);
        },
        getCustomers () {
            const dispatchFunction = async (params) => {
                let chunk = [];
                await this.$store.dispatch('customers/getCustomers', params).then(() => {
                    chunk = this.listingCustomers.map(customer => {
                        return {
                            id: customer.value,
                            name: customer.text,
                            elementType: 'customer'
                        };
                    });
                    this.customersList = this.customersList.concat(chunk);
                });
                return chunk;
            };

            const getPointsLength = () => { return this.customersList.length; };
            const getTotalPointItemsAmount = () => { return this.totalCustomersAmount; };

            this.loadAllElementsInitially({}, dispatchFunction, getPointsLength, getTotalPointItemsAmount, () => { this.loadedAllCustomers = true; });
        },
        getActiveElements () {
            this.allElementsList = this.elementsList;

            if (!this.activeSteps) return [];

            const filteredElements = this.elementsList.filter((el) => this.activeSteps.find(activeStep => activeStep.employee.id === el.id));

            this.activeElements = filteredElements.map(el => {
                const activeStep = this.activeSteps.find(activeStep => activeStep.employee.id === el.id);

                return {
                    ...el,
                    parent_id: activeStep.order === 0 ? null : activeStep.order,
                    template: {
                        id: activeStep.template.id,
                        value: activeStep.value
                    },
                    value: activeStep.value,
                    timeSinceSequenceStart: activeStep.timeSinceSequenceStart,
                    tolerance: activeStep.tolerance
                };
            });

            this.$store.dispatch('cyclicRevisions/setSelectedTracksElements', this.activeElements);
        },
        async getElements (params = null, withConcatenation = true) {
            const dispatchFunction = async (params) => {
                let chunk = [];
                await this.$store.dispatch('employees/getEmployees', params).then(() => {
                    chunk = this.employees.map(element => {
                        return {
                            id: element.value,
                            name: element.text,
                            elementType: STEP_ELEMENT_TYPE_ENUM.ELEMENT
                        };
                    });
                    if (withConcatenation) this.elementsList = this.elementsList.concat(chunk);
                });
                return chunk;
            };
            if (params === null) {
                params = { 'filter[is_item]': true };
                const getItemsLength = () => { return this.elementsList.length; };
                const getTotalItemsAmount = () => { return this.totalItemsAmount; };
                await this.loadAllElementsInitially(params, dispatchFunction, getItemsLength, getTotalItemsAmount, this.getActiveElements);
            } else {
                this.elementsList = await dispatchFunction(params);
            }
        },
        getActiveEmployees () {
            this.allEmployeesList = this.employeesList;

            if (!this.activeSteps) return [];

            const filteredEmployees = this.employeesList.filter((el) => this.activeSteps.find(activeStep => activeStep.employee.id === el.id));

            this.activeEmployees = filteredEmployees.map(el => {
                const activeStep = this.activeSteps.find(activeStep => activeStep.employee.id === el.id);

                return {
                    ...el,
                    parent_id: activeStep.order === 0 ? null : activeStep.order,
                    template: {
                        id: activeStep.template.id,
                        value: activeStep.value
                    },
                    value: activeStep.value,
                    timeSinceSequenceStart: activeStep.timeSinceSequenceStart,
                    tolerance: activeStep.tolerance
                };
            });

            this.$store.dispatch('cyclicRevisions/setSelectedTracksEmployees', this.activeEmployees);
        },
        async getEmployees (params = null, withConcatenation = true) {
            const dispatchFunction = async (params) => {
                let employeesChunk = [];
                await this.$store.dispatch('employees/getEmployees', params).then(() => {
                    employeesChunk = this.employees.map(employee => {
                        return {
                            id: employee.value,
                            name: employee.text,
                            elementType: STEP_ELEMENT_TYPE_ENUM.EMPLOYEE
                        };
                    });
                    if (withConcatenation) this.employeesList = this.employeesList.concat(employeesChunk);
                });
                return employeesChunk;
            };
            if (params === null) {
                params = { 'filter[is_item]': false };
                const getEmployeesLength = () => { return this.employeesList.length; };
                const getTotalEmployeesAmount = () => { return this.totalEmployeesAmount; };
                await this.loadAllElementsInitially(params, dispatchFunction, getEmployeesLength, getTotalEmployeesAmount, this.getActiveEmployees);
            } else {
                this.employeesList = await dispatchFunction(params);
            }
        },
        getInspectionTemplates () {
            this.$store.dispatch('manageTrack/getStepInspectionTemplates', this.point.id).then(() => {
                this.inspectionTemplates = this.inspectionsTemplates.get(this.point.id) || [];
            });
        },
        async loadAllElementsInitially (params, dispatchFunction, listLength, totalAmount, callback) {
            let i = 1;
            let loopContinue = true;
            while (loopContinue) {
                params['page[number]'] = i;
                i += 1;
                const chunk = await dispatchFunction(params);
                if (chunk.length === 0 || listLength() >= totalAmount()) {
                    loopContinue = false;
                    callback && callback();
                }
            }
        },
        handleTrackElementChange (changedElementsList, type) {
            if (this.localSelectedTrackComponents.length === 0) {
                this.localSelectedTrackComponents.push(...changedElementsList);
            } else if (changedElementsList.length === 0) {
                this.removeTrackElements([type]);
            } else {
                const localSelectedTrackElements = this.localSelectedTrackComponents.filter((el) => el.elementType === type);
                const newElements = changedElementsList.filter((el) => !localSelectedTrackElements.includes(el));

                const elementDifference = newElements.length - localSelectedTrackElements.length;

                if (elementDifference > 0) {
                    this.addElementByType(newElements);
                } else if (elementDifference < 0) {
                    this.removeElementByType(type, changedElementsList);
                }
            }
        },
        removeTrackElements (typesList) {
            const indexList = [];
            this.localSelectedTrackComponents.forEach((element, index) => {
                if (typesList.includes(element.elementType)) {
                    indexList.unshift(index);
                }
            });
            indexList.forEach(index => {
                this.localSelectedTrackComponents.splice(index, 1);
            });
        },
        addElementByType (elementsArray) {
            elementsArray.forEach((item, index) => {
                const locIndex = this.localSelectedTrackComponents.findIndex((el) => ((el.id === item.id) && (el.elementType === item.elementType)));
                if (locIndex === -1) {
                    this.localSelectedTrackComponents.push(elementsArray[index]);
                }
            });
        },
        recalculateElementsArray (elementsArray) {
            elementsArray.forEach((element, index) => {
                element.parent_id = index === 0 ? null : index;
            });
            this.steps = elementsArray;
        },
        handleCopy (item) {
            this.localSelectedTrackComponents.push({ ...item });
            this.recalculateElementsArray(this.localSelectedTrackComponents);
        },
        handleDelete (item) {
            const indexes = this.localSelectedTrackComponents.filter(el => el.id === item.id && el.elementType === item.elementType);
            this.localSelectedTrackComponents.splice(this.localSelectedTrackComponents.indexOf(item), 1);
            this.recalculateElementsArray(this.localSelectedTrackComponents);
            if (indexes.length === 1) {
                this.$refs[this.treesRefs[indexes[0].elementType]].removeSelected(indexes[0]);
            }
        },
        removeElementByType (type, elementsArray) {
            const idsList = elementsArray.map((el) => (el.id));
            const elementsByType = this.localSelectedTrackComponents.filter((el) => el.elementType === type);
            const elementsToRemove = elementsByType.filter((el) => !idsList.includes(el.id));
            for (const el of elementsToRemove) {
                const index = this.localSelectedTrackComponents.indexOf(el);
                this.localSelectedTrackComponents.splice(index, 1);
            }
        },
        close () {
            this.$store.dispatch('manageTrack/clearTrack');
            this.$router.push({ name: 'tracks' });
        },
        async saveTrack () {
            const trackBasicInfo = await this.$refs.trackBasicConfig.validate();
            const trackForm = await this.$refs.trackForm.validate();
            const trackConfigTable = await this.$refs.trackConfigTable.validate();
            if (trackBasicInfo && trackConfigTable && trackForm) {
                this.loader = true;
                this.$store.dispatch(this.id ? 'manageTrack/updateTrack' : 'manageTrack/createTrack').then(() => {
                    this.$store.dispatch('manageTrack/clearTrack');
                    this.$router.push({ name: 'tracks' });
                }).finally(() => {
                    this.loader = false;
                });
            }
        },
        updateAutocomplete (event, item, items) {
            const updatedItem = items.findLast(item => item.value === event);

            if (updatedItem) {
                this[item].id = updatedItem.value;
                this[item].name = updatedItem.text;
            } else {
                this[item].id = event;
                this[item].name = '';
            }

            if (item === 'point') {
                this.inspectionTemplates = [];
            }
        },
        getFilteredElements (allList, currentList) {
            return allList?.map(el => ({
                ...el,
                locked: !currentList.find(curr => curr.id === el.id)
            })
            ) || [];
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (to.params.id ? !vm.$can(vm.$abilityActions.UPDATE, vm.$abilitySubjects.TRACKS) : !vm.$can(vm.$abilityActions.CREATE, vm.$abilitySubjects.TRACKS)) vm.$router.push({ name: 'tracks' });
            vm.getActiveTrack(to.params.id);
            vm.getTreesElements();
            vm.getIncidentsTypes();
            vm.getEmployeesGroups();
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('cyclicRevisions/clearSelectedOptions');
        next();
    }
};
</script>

<style scoped lang="scss">
    fieldset {
        border: none;
    }

    .isLoading {
        pointer-events: none;
        background: rgba(0,0,0,.12);
        > div {
            opacity: 0.3
        }
    }
</style>
